import React, { Component } from 'react'
import './loading.css'

function App() {

  return (
	<>
  	</>
  );
}

export default App;
