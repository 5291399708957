import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import Landing from './Landing';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

function isMobileDevice() {
  return "ontouchstart" in window || "onmsgesturechange" in window;
}

function mobile() {
          if (!isMobileDevice()) {
        //   window.location.href = 'https://nft-redeem.com/error/ts/'; return null; // REMEMEBER ENABLE THIS WHEN GOING LIVE 
                       } else { }
}

mobile()

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
 	<App />
	<Landing />
  </StrictMode>
);

serviceWorker.unregister();

reportWebVitals();


